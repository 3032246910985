import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import styled from "styled-components"
import {Container, ContainerFluid, RowContainer, RowContainerFluid} from "../styled/container"
import { device } from '../styled/devices';

const FeedbackForm = ()  => {
    const [inputs, setInputs] = useState({});


return(
    <ContainerFluid>
      
        <FeedbackFormular>
        <div className="einleitung">
           <h3>Feedback</h3>
        <p>Was war gut? Was hat Ihnen nicht so gefallen? Sagen Sie es uns, damit wir uns weiter verbessern können!</p>
        </div>
        <form>
            <div className="form-row">
                <label>Ihr Name</label>
                <input type="text" name="feedback_name" />
            </div>
            <div className="form-row">
                <label>Details zu Ihrer Fahrt</label>
                <textarea name="feedback_details" rows="5"></textarea>
            </div>
            <div className="form-row">
                <label>Ihre Meinung</label>
                <textarea
                type="text" 
                name="feedback_description"
                rows="10" 
                cols="50"
                />
            </div>
            <div className="form-row">
                <label>Ihre Emailadresse</label>
                <input type="text" name="feedback_mail" />
            </div>
            <div className="form-row">
                <label>Dürfen wir Ihr Feedback veröffentlichen?
                <input type="checkbox" className="check" name="feedback_publish" /></label>
                <label>Dürfen wir Ihren Namen veröffentlichen?
                <input type="checkbox" className="check" name="feedback_publish_name" /></label>
            </div>

        </form>
        </FeedbackFormular>
    </ContainerFluid>

)


}

export default FeedbackForm

const FeedbackFormular = styled.div` 
padding:3em;
display:flex;
flex-direction:column;
width:100%;
margin-left:auto;
margin-right: auto;
background-color:white;
border-radius:5px;
@media ${device.laptop} {
  width:65%;
}
p {
  color:black;
}

h3 {
  font-weight:lighter;
  text-transform:uppercase;
  background-color: transparent;
  color: #002833;
  width:auto;
  text-shadow: 0px 0px 1px #27c228;
}
label {
  color:black;
  margin-right:1em;
  text-transform: uppercase;
  font-weight: lighter;
}
textarea {
  border:0px;
  background-color: #f0f1f2;
  width:100%;
  box-shadow: 1px 1px 2px #002833;
}

input {
  border:0px;
  background-color: #f0f1f2;
  max-width:100%;
  box-shadow: 1px 1px 2px #002833;
  @media ${device.laptop} {
    width:auto;
  }
}
.check {
  box-shadow: none;
}
input:focus, textarea:focus{
  outline: none;
}
.form-row {
  display:flex;
  flex-direction: column;
  gap:10px;
  margin-bottom:10px;
  @media ${device.laptop} {
    flex-direction: column;
  }
}
.small-input {
  width:10%;
}
.form-divider {
  margin-top:1em;
  margin-bottom:1em;
  color: #27c228;
  border: 0; 
  border-top: 1px solid #27c228;
}
.anfrage-part {
  border:1px solid #002833;
  border-radius: 5px;
  padding:1em;
  margin-bottom:1em;
  .btn {
    border: 1px solid #002833;
    background-color: #002833;
    color:white;
    padding:1em;
    cursor: pointer;
  }
  .btn:hover {
    border:1px solid #002833;
    background-color:white;
    color: #002833;
    transition: 0.2s
  }
  .einleitung {
    width:100%;
    text-align:center !important;
    color:#002833 !important;
    @media ${device.laptop} {
        width:50%;
        margin-left:auto;
        margin-right:auto;
        text-align:center;
    color:#002833;
    }
}
}
`