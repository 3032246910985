import React, {useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import {BgImageOverlay, BgImageContainer} from "../styled/container"
import HTML2React from 'html2react'
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import { device, size } from '../styled/devices';

import ImageLeft from "../components/imageleft"
import CTA from "../components/cta"
import Seo from "../components/seo"
import ImageRight from "../components/imageright"
import Team from "../components/team"
import LargeImage from "../components/largeimage"
import Card from "../components/card"
import AnfrageForm from "../components/anfrageformular"
import FeedbackForm from "../components/feedback"
import Bus from "../components/bus"
import {Container, RowContainer, RowContainerFluid, ContainerFluid} from "../styled/container"
import { window, document, exists } from 'browser-monads';
import Loading from "../components/loading"
// import AOS from 'aos';
// import 'aos/dist/aos.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { faShower } from "@fortawesome/free-solid-svg-icons"



const PageTemplate = ({ data }) => {
    // useEffect(() => {
    //     AOS.init({duration: 1200,});
    // })
  console.log("Data", data)
    const style = {
        backgroundImage: 'url(' + data.wpPage.featuredImage.node.sourceUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'

    }
    let showAnfrage = false;
    let showContact = false;
    let showTemp = false;
    if (data.wpPage.guid.indexOf('31') > -1 ) {
        showAnfrage = true;
        showTemp = false;
    }
    if (data.wpPage.guid.indexOf('25') > -1 ) {
        showContact = true;
    }

    // showAnfrage = false;
    // showContact = false;


    const sections = JSON.parse(data.wpPage.contentSections)
    const gallery = JSON.parse(data.wpPage.customGallery)
    console.log("Gallery", gallery)
    let cards = [];
    let cardsAdded = false;
    let cardStyle = "dark";
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].sectionLayout === 'card') {
            if (cardStyle == 'dark' && cardStyle != sections[i].sectionStyle) {
                cardStyle = sections[i].sectionStyle;
            }
               cards.push(sections[i])
            }
        }
        
        
    const cardFunc = () => {
        console.log("Style", cardStyle)
        let dark = '';
        if (cardStyle == 'dark') {
            dark = true;
        } else {
            dark = false;
        }
        return(
            <>
            {dark ?  <RowContainerFluid>
                {cards.map((section) => (
                <Card dark={cardStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
                ))}
            </RowContainerFluid> :
            <RowContainer>
                {cards.map((section) => (
                <Card dark={cardStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
                ))}
            </RowContainer>
                }
                 </>
            )
            };
       
            let busses = [];
            let busAdded = false;
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].sectionLayout === 'bus') {
                       busses.push(sections[i])
                    }
                }
                // const busStyle = busses[0].sectionStyle;
                
            const busFunc = () => {
               
                return(
                    
                    <RowContainer>
                        {busses.map((section) => (
                        <Bus dark={section.sectionStyle} content={section.sectionContent} image={section.sectionImageUrl}/>
                        ))}
                    </RowContainer>
                   
                    )
                    };
    
    console.log("Sections", sections)
    let bgImage = '';
    let output = [];
    let value = '';
    cardsAdded = false;
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].sectionLayout === 'img-right') {
            value = <ImageRight aos='fade-up' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
            
            output.push(value);
            console.log("right", value)
        }
        if (sections[i].sectionLayout === 'img-left') {
            value = <ImageLeft aos='fade-up' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl}/>;
           
            output.push(value);
            console.log("left", value)
        }
        if (sections[i].sectionLayout === 'team-left') {
            value = <Team aos='fade-left' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl} direction="left"/>;
           
            output.push(value);
            console.log("left", value)
        }
        if (sections[i].sectionLayout === 'team-right') {
            value = <Team aos='fade-right' dark={sections[i].sectionStyle} content={sections[i].sectionContent} image={sections[i].sectionImageUrl} direction="right"/>;
           
            output.push(value);
            console.log("left", value)
        }
        if (sections[i].sectionLayout === 'card' && cardsAdded == false) {
            value = cardFunc()
            output.push(value)
            cardsAdded = true;
        }
        if (sections[i].sectionLayout === 'bus' && busAdded == false) {
            value = busFunc()
            output.push(value)
            busAdded = true;
        }
        if (sections[i].sectionLayout === 'cta') {
            value = <CTA aos='fade-left' dark={sections[i].sectionStyle} content={sections[i].sectionContent} />
            output.push(value)
        }
        
    }
    

  return (
    <Layout>
      
            {gallery[0] === false ?
                <FeaturedImage style={style}>
                    <BgImageContainer>
                        <h1><div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} /></h1>
                    </BgImageContainer>

                    <div className="custom-shape-divider-bottom-1667375169">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
                                  className="shape-fill"></path>
                        </svg>
                    </div>
                </FeaturedImage>
                :
                <FeaturedSwiper>

                <Swiper className="swiper-feature"
                        modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        loop={true}
                       
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                          }}
                        pagination={{clickable: true}}

                >
                    {gallery.map((image) => (
                        bgImage = {
                            backgroundImage: 'url(' + image + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',

                        },
                            <SwiperSlide style={bgImage}>
                                <BgImageContainer style={{zIndex: "3"}}>
                                    <h1><div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} /></h1>
                                </BgImageContainer>
                            </SwiperSlide>
                    ))
                    }

                </Swiper>
            </FeaturedSwiper>
                }
        {output != '' ?
            output.map((item) => {
                return (item)
            })
    :null}
    {showAnfrage ? <AnfrageForm /> : null}
    {showTemp? <ContainerFluid style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}><h3>Sie wollen einen Bus mieten?</h3><p>Dann senden Sie uns einfach eine Email an</p><p><a href="mailto:bus@richard.de">busrichard.de</a></p></ContainerFluid>  : null}
    {showContact ? <FeedbackForm /> : null }
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String) {
      wpPage(id: {eq: $id}) {
        title
        guid
        id
        content
        contentSections
        featuredImage {
            node {
              sourceUrl
            }
          }
          customGallery
    }
  }
`
export default PageTemplate

  const FeaturedSwiper = styled.div`
  height:99vh;
  width:100vw;
  .swiper-feature {
  height:99vh;
  width:100vw;
  }
  ` 

const FeaturedImage = styled.div`
position:relative;
width: 100vw;
height: 100vh;

.custom-shape-divider-bottom-1667375169 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1667375169 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1667375169 .shape-fill {
    fill: #FFFFFF;
}

@media (max-width:767px ) {
    .custom-shape-divider-bottom-1667375169 {
        display:none;
    }
}
`
const schema = `{
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'url': 'https://www.richard.de',
      'name': 'Dr. Richard Deutschland',
      'contactPoint': {
        '@type': 'ContactPoint',
        'telephone': ' +49 89 590 68 77 00',
        'contactType': 'Customer Support'
      },
      "location": {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "München",
          "addressCountry: "Germany",
          "streetAddress": "Bayerstrasse 83",
          "postalCode": "80335"
        },
        "logo": "https://richard.de/static/c97486d1cae0250ec5a8a3e0c475a59d/31451/cropped-logo.webp",
        "service": {
            "@type": "Service",
            "serviceType":"Busreisen"
        }
    }
  }
  `


export const Head = ({data}) => (
   <Seo title={data.wpPage.title} description={data.wpPage.content} children={schema}/>
  )